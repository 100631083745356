//****************************************************************************************************
// Imports
//****************************************************************************************************

//================================================================================
// External
//================================================================================

import React, { useState, useEffect } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

//================================================================================
// Internal
//================================================================================

import ChartHistoryId from './ChartHistoryId';

//****************************************************************************************************
// OperationSpace
//****************************************************************************************************

//================================================================================
// Styling
//================================================================================

const styles = theme => ({
  whiteTextField: {
      backgroundColor: '#FFFFFF',
      marginTop: '8px',
      marginBottom: '8px',
  },
  whiteMenu: {
  },
  whiteInput: {
      paddingLeft: '10px',
      backgroundColor: '#FFFFFF',
      hover: {
          height: '0px',
          backgroundColor: '#FFFFFF',
      }
  },
  whiteSelect: {
      backgroundColor: '#FFFFFF',
  },
});

//================================================================================
// Recursive Temperature Limit Retrieval
//================================================================================

const getFunctionFromTags = (tags) => {
    return tags.filter(tag => tag.key === "function")[0].value;
}

const filterForTemperatureLimits = (systemMetrics) => {
    return systemMetrics.filter(sm => (sm.systemMetricTemplate.name === "space_temperature_air" && (getFunctionFromTags(sm.systemMetricTags) === "limit_low" || getFunctionFromTags(sm.systemMetricTags) === "limit_high")));
}

const getParentTemperatureLimits = (spaces,selectedSpace) => { // Recursively get the correct (parent) space that defines the 'limit_low/high' for the 'space_temperature_air' system-metric
    let temperatureLimitMetrics = filterForTemperatureLimits(selectedSpace.systemMetrics);
    //--- Limits are missing ---
    if(temperatureLimitMetrics.length === 0 && selectedSpace.slug !== "space_0000"){
        const parentSlug  = selectedSpace.parentSpace ? selectedSpace.parentSpace.slug : "space_0000"; // Default to 'BuildingSpace' if not parent is defined
        const parentSpace = spaces.find(s => s.slug === parentSlug);
        if (parentSpace) temperatureLimitMetrics = temperatureLimitMetrics.concat(getParentTemperatureLimits(spaces,parentSpace));
    }
    return temperatureLimitMetrics;
}

//================================================================================
// Main Component
//================================================================================

const OperationSpace = props => {
  const { data, classes } = props;
  const [selectedSpaceSlug, setSelectedSpaceSlug] = useState("");
  const [systemMetricIdList, setSystemMetricIdList] = useState([]);

  useEffect(() => {
    if (data.me?.selectedBuilding && data.me?.selectedBuilding?.spaces?.length!==0){
      if (selectedSpaceSlug === "")
      {
        const firstSpaceSlug = data.me.selectedBuilding.spaces.sort((a, b) => a.displayName.localeCompare(b.displayName))[0].slug;
        if (firstSpaceSlug !== selectedSpaceSlug) { setSelectedSpaceSlug(firstSpaceSlug); }
      } else {
        const selectedSpace = data.me.selectedBuilding.spaces.find(s => s.slug === selectedSpaceSlug);
        selectedSpace.systemMetrics = selectedSpace.systemMetrics.concat(getParentTemperatureLimits(data.me.selectedBuilding.spaces,selectedSpace)); // Extend with parent limits, if not defined
        setSystemMetricIdList(
          selectedSpace.systemMetrics.map(sm => sm.id).concat(
            ...selectedSpace.systems.map(s => s.systemMetrics.map(sm => sm.id))
        ))
      }
    }
  }, [data.me, selectedSpaceSlug]);

  if (data.error) {
    return (<Paper style={{ padding: 25 }}><Typography gutterBottom>Error fetching chart data!</Typography></Paper>)
  }
  if (data.loading) {
    return (<Paper style={{ padding: 25 }}><CircularProgress size={50} color="secondary" /></Paper>)
  }
  if(!data.me.selectedBuilding || data.me.selectedBuilding.spaces.length===0) {
    return (<Paper style={{ padding: 25 }}><Typography gutterBottom>No data available</Typography></Paper>)
  }

  return (
    <div>
      <Paper style={{ padding: 25 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              id="select-space"
              select
              value={selectedSpaceSlug}
              onChange={(event) => setSelectedSpaceSlug(event.target.value)}
              margin="normal"
              className={classes.whiteTextField}
              SelectProps={{
                  MenuProps: {
                      className: classes.whiteMenu,
                  },
              }}
              inputProps={{
                  className: classes.whiteInput,
              }}
              >
              { data.me.selectedBuilding.spaces.sort((a, b) => a.displayName.localeCompare(b.displayName)).map(option => (
                  <MenuItem key={option.slug} value={option.slug}>
                      {option.displayName}
                  </MenuItem>
              )) }
            </TextField>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <ChartHistoryId
              systemMetricIds={systemMetricIdList}
              systemMetricGroups={[
                {
                  "systemMetricTemplateName": "space_temperature_air",
                  "systemMetricTagFunction": "limit_high",
                  "axis": "temperature_air",
                  "color": "#4393c3",
                  "dashed": true,
                  "addToLegend": true,
                },
                {
                  "systemMetricTemplateName": "space_temperature_air",
                  "systemMetricTagFunction": "percentile_high",
                  "axis": "temperature_air",
                  "color": "red",
                  "dashed":true,
                  "addToLegend": true,
                  "systemMetricTagTimewindow": "past"
                },
                {
                  "systemMetricTemplateName": "space_temperature_air",
                  "systemMetricTagFunction": "sensor",
                  "axis": "temperature_air",
                  "color": "red",
                  "addToLegend": true,
                },
                {
                  "systemMetricTemplateName": "space_temperature_air",
                  "systemMetricTagFunction": "setpoint",
                  "axis": "temperature_air",
                  "color": "red",
                  "dashed": true,
                  "addToLegend": true,
                },
                {
                  "systemMetricTemplateName": "space_temperature_air",
                  "systemMetricTagFunction": "percentile_low",
                  "axis": "temperature_air",
                  "color": "red",
                  "dashed":true,
                  "addToLegend": true,
                  "systemMetricTagTimewindow": "past"
                },
                {
                  "systemMetricTemplateName": "space_temperature_air",
                  "systemMetricTagFunction": "limit_low",
                  "axis": "temperature_air",
                  "color": "#1a9850",
                  "dashed": true,
                  "addToLegend": true,
                },

                {
                  "systemMetricTemplateName": "vrv_status",
                  "systemMetricTagFunction": "setpoint",
                  "axis": "status",
                  "color": "#763F08",
                  "addToLegend": true,
                },
                {
                  "systemMetricTemplateName": "vrv_operation_mode",
                  "systemMetricTagFunction": "setpoint",
                  "axis": "status",
                  "color": "#649E2A",
                  "addToLegend": true,
                },
                {
                  "systemMetricTemplateName": "vrv_fan_speed",
                  "systemMetricTagFunction": "setpoint",
                  "axis": "status",
                  "color": "#575AB6",
                  "addToLegend": true,
                },
              ]}
              systemMetricAxes={[
                {
                  "name": "status",
                  "label": "Status",
                },
                {
                  "name": "temperature_air",
                  "label": "Air temperature [°C]",
                },
              ]}
            />
          </Grid>
        </Grid>
      </Paper>
    </div>
  )
}

//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// GraphQL Query
//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

const getCurrentOperationSpaces = gql`
query getCurrentOperationSpaces {
  me {
    id
    email
    name
    role
    selectedBuilding {
      id
      projectID
      lastDataPushDateTime
      spaces {
        id
        displayName
        slug
        parentSpace {
          id
          slug
        }
        systemMetrics(where:{systemMetricTemplate:{name:"space_temperature_air"}}) {
          id
          systemMetricTemplate {
            id
            name
          }
          systemMetricTags{
            key
            value
          }
        }
        systems (where:{systemTemplate:{name:"systemTemplate_vrv"}}) {
          id
          displayName
          slug
          systemTemplate {
            id
            name
          }
          systemMetrics {
            id
            systemMetricTemplate {
              id
              name
            }
          }
        }
      }
    }
  }
}`;

export default graphql(getCurrentOperationSpaces)(withStyles(styles)(OperationSpace));
