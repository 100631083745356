import React, {useMemo, useState} from 'react';
import {graphql} from 'react-apollo';
import gql from 'graphql-tag';

import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import LinkIcon from '@material-ui/icons/Link';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import Avatar from '@material-ui/core/Avatar';

import {CurrentUserContext} from '../contexts/CurrentUser';
import {computeStatusColor} from "./ComputeStatusColor";

// Function to get background color based on the alert level
const getBackgroundColor = (level, client) => {
    switch (level) {
        case 'ok':
        case 'info':
            return client.colorStateOk;
        case 'warning':
            return client.colorStateWarning;
        case 'error':
            return client.colorStateError;
        default:
            return client.colorStateInactive;
    }
};

// Custom sorting order for levels
const levelOrder = ['error', 'warning', 'info', 'ok', null];

const ProjectOverviewBuildingStates = (props) => {
    const [sortConfig, setSortConfig] = useState({key: 'green_ball_status', direction: 'ascending'});

    const handleSort = (key) => {
        setSortConfig(prevState => {
            let direction = 'descending';
            if (prevState.key === key && prevState.direction === 'descending') {
                direction = 'ascending';
            }
            return {key, direction};
        });
    };

    const sortedBuildings = useMemo(() => {
        if (!props.data.me) return [];

        const buildings = [...props.data.me.client.buildings];
        const {key, direction} = sortConfig;

        buildings.sort((a, b) => {
            if (key === 'projectID') {
                return direction === 'ascending'
                    ? a.projectID.localeCompare(b.projectID)
                    : b.projectID.localeCompare(a.projectID);
            } else if (key === 'green_ball_status') {
                const aColor = computeStatusColor(a.controlStatus, a.controlStatusSetpoint, props.data.me, a);
                const bColor = computeStatusColor(b.controlStatus, b.controlStatusSetpoint, props.data.me, b);
                return direction === 'ascending' ? aColor.localeCompare(bColor) : bColor.localeCompare(aColor);
            } else {
                const aState = a.buildingStates.find(bs => bs.title === key);
                const bState = b.buildingStates.find(bs => bs.title === key);

                const aLevel = aState ? aState.level : null;
                const bLevel = bState ? bState.level : null;

                const aIndex = levelOrder.indexOf(aLevel);
                const bIndex = levelOrder.indexOf(bLevel);

                if (direction === 'ascending') {
                    return aIndex - bIndex;
                } else {
                    return bIndex - aIndex;
                }
            }
        });

        return buildings;
    }, [props.data.me, sortConfig]);

    return (
        <CurrentUserContext.Consumer>
            {me => {
                // Check if data is loading or there's an error
                if (props.data.error || props.data.loading || !props.data.me) {
                    return <Typography></Typography>;
                }

                // Extract and sort building states
                const buildingStates = props.data.me.client.buildings.reduce((statesList, building) => {
                    const newStates = building.buildingStates.filter(bs => !statesList.some(s => s.title === bs.title));
                    return statesList.concat(newStates);
                }, []);

                buildingStates.sort((a, b) => {
                    if (!a.category && !b.category) return -1;
                    if (!a.category) return 1;
                    if (!b.category) return -1;
                    if (a.category === b.category) return a.title.localeCompare(b.title);
                    return a.category.localeCompare(b.category);
                });

                // Ensure comfort is the first column
                const sortedBuildingStates = buildingStates.sort((a, b) => {
                    const order = ['comfort', 'steerability', 'writability', 'gateway availability', 'control', 'gateway health', 'Greybox', 'm&v', 'sanity-checks', 'steering'];
                    const aIndex = order.indexOf(a.title);
                    const bIndex = order.indexOf(b.title);
                    return aIndex - bIndex;
                });

                // Render the table with building states
                return (
                    <Grid container style={{overflow: 'auto'}}
                          hidden={!(me.role === 'OPERATOR' || me.role === 'ADMIN' || me.role === 'SUPERADMIN')}>
                        <Grid item xs={12}>
                            <Table size="small" >
                                <TableHead>
                                    <TableRow>
                                        <TableCell onClick={() => handleSort('green_ball_status')}>Green ball status</TableCell>
                                        <TableCell onClick={() => handleSort('projectID')}>Project</TableCell>
                                        {sortedBuildingStates.map(bs => (
                                            <TableCell key={bs.id} align="center" onClick={() => handleSort(bs.title)}>
                                                {bs.title}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {sortedBuildings.map(building => (
                                        <TableRow key={building.id}>
                                            <TableCell>
                                                <Avatar
                                                    aria-label="Building"
                                                    style={{backgroundColor: computeStatusColor(building.controlStatus, building.controlStatusSetpoint, me, building)}}>
                                                    &nbsp;
                                                </Avatar>
                                            </TableCell>
                                            <TableCell><a style={{color: 'black', textDecoration: 'none',}}
                                                          href={`/buildings/${building.projectID}/state`}>
                                                {building.displayName}<br/> ({building.projectID}) <LinkIcon
                                                fontSize="small"/>
                                            </a></TableCell>

                                            {sortedBuildingStates.map(bs => {
                                                const state = building.buildingStates.find(bbs => bbs.title === bs.title);
                                                return state ? (
                                                    <TableCell key={bs.id} align="center"
                                                               title={state.message.replace(/ ; /gi, "\n")}>
                                                        <RadioButtonCheckedIcon
                                                            style={{color: getBackgroundColor(state.level, props.data.me.client)}}/>
                                                    </TableCell>
                                                ) : (
                                                    <TableCell key={bs.id} align="center">
                                                        <RadioButtonUncheckedIcon
                                                            style={{color: getBackgroundColor('inactive', props.data.me.client)}}/>
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Grid>
                    </Grid>
                );
            }}
        </CurrentUserContext.Consumer>
    );
};

// GraphQL query to get current user data
const getCurrentUser = gql`
  query getCurrentUser {
    me {
      id
      email
      name
      role
      externalBuildingRoles {
        building {
          id
          projectID
          displayName
          buildingStates {
            id
            title
            category
            level
            message
            alertValidated
            createdAt
            updatedAt
          }
        }
      }
      selectedBuilding {
        id
        projectID
        displayName
        lastDataPushDateTime
        image {
          id
          file {
            id
            url
          }
        }
      }
      client {
        id
        displayName
        colorStateActive
        colorStateInactive
        colorStateActivating
        colorStateDeactivating
        colorStateUndefined
        colorStateError
        colorStateWarning
        colorStateOk
        logo {
          id
          file {
            id
            url
          }
        }
        buildings {
          id
          projectID
          displayName
          controlStatus
          controlStatusSetpoint
          activationDateSteering
          buildingStates {
            id
            title
            category
            level
            message
            alertValidated
            createdAt
            updatedAt
          }
        }
        users {
          id
          name
          email
          role
        }
      }
    }
  }
`;

export default graphql(getCurrentUser)(ProjectOverviewBuildingStates);
