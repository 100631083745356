import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'

import { graphql } from 'react-apollo'
import gql from 'graphql-tag'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'

import * as d3 from 'd3-time'
import moment from 'moment'
import { TimeSeries, TimeRange, TimeEvent } from "pondjs"
import { Resizable, ChartContainer, Charts, ChartRow, YAxis, LineChart, EventMarker, Legend, styler } from "react-timeseries-charts"

const sequentialColors = ['Set3', 'Paired', 'Blues', 'BuGn', 'BuPu', 'GnBu', 'Greens', 'Greys', 'Oranges', 'OrRd', 'PuBu', 'PuBuGn', 'PuRd', 'Purples', 'RdPu', 'Reds', 'YlGn', 'YlGnBu', 'YlOrBr', 'YlOrRd'];

const getColorStyler = (columnNames, colorCodeOrMetricName) => {
    const colorStyler = (colorCodeOrMetricName === "*")
        ? styler(columnNames, "Set1")
        : sequentialColors.indexOf(colorCodeOrMetricName) > -1
            ? styler(columnNames, colorCodeOrMetricName)
            : styler(columnNames.map(name => ({
                key: name,
                color: colorCodeOrMetricName,
            })));
    return colorStyler;
}

const styles = theme => ({
    wrapped: {
      flexWrap: 'wrap',
    },
})

const axisStyle = {
    labelFont: "montserrat",
    labelColor: "#BBBBBB",
}
const getFunctionFromTags = (tags) => (
    tags.filter(tag => tag.key === "function")[0] && tags.filter(tag => tag.key === "function")[0].value
)
const getRwcFromTags = (tags) => (
    tags.filter(tag => tag.key === "rwc")[0] && tags.filter(tag => tag.key === "rwc")[0].value
)
const getTimeWindowFromTags = (tags) => (
    tags.filter(tag => tag.key === "timewindow")[0] && tags.filter(tag => tag.key === "timewindow")[0].value
)
const getMinValueFromData = (data, axisName) => (
    (data && axisName)
    ? (axisName === "status")
    ? 0.0
    : Math.min(
        ...data.filter(smg => smg.axis === axisName).map(
            smg => Math.min(
                ...smg.series.columns().map(columnName => smg.series.min(columnName))
            )
        )
    )
    : 0.0
)
const getMaxValueFromData = (data, axisName) => (
    (data && axisName)
    ? (axisName === "status")
    ? Math.max(1.0, Math.max(
        ...data.filter(smg => smg.axis === axisName).map(
            smg => Math.max(
                ...smg.series.columns().map(columnName => smg.series.max(columnName))
            )
        )
    ))
    : Math.max(
        ...data.filter(smg => smg.axis === axisName).map(
            smg => Math.max(
                ...smg.series.columns().map(columnName => smg.series.max(columnName))
            )
        )
    )
    : 1.0
)

const getTimeseriesFromPayload = (id, name, payload, timeRange) => (
    (payload == null) || (timeRange == null)
    ? null
    : new TimeSeries({
        name: name,
        columns: ["time", id],
        points: Object.keys(payload)
            .map(k => parseInt(k, 10))
            .filter(k => k >= timeRange.begin().getTime() && k <= timeRange.end().getTime())
            .sort()
            .map(k => [ k, payload[k] ])
    }).clean(id)
)

const getDataFromSystemMetric = (systemMetric, historyTimeRange, forecastTimeRange) => (
    TimeSeries.timeSeriesListMerge({
        name: "data",
        seriesList: [
            getTimeseriesFromPayload(systemMetric.id, systemMetric.systemMetricTemplate.name, systemMetric.payload, historyTimeRange),
            getTimeseriesFromPayload(systemMetric.id, systemMetric.systemMetricTemplate.name, systemMetric.payloadForecast, forecastTimeRange),
        ].filter(ts => ts !== null)
    })
)

const getData = (data, configuredGroups, historyTimeRange, forecastTimeRange) => (
    configuredGroups.map((configuredGroup, index) => {
        const filteredData =
            Array.from(data)
                .filter(sm => sm.systemMetricTemplate.name === configuredGroup.systemMetricTemplateName)
                .filter(sm => (configuredGroup.systemMetricTagFunction === undefined || configuredGroup.systemMetricTagFunction === getFunctionFromTags(sm.systemMetricTags)))
                .filter(sm => (configuredGroup.systemMetricTagRwc === undefined || configuredGroup.systemMetricTagRwc === getRwcFromTags(sm.systemMetricTags)))
                .filter(sm => (configuredGroup.systemMetricTagTimewindow === undefined ||  configuredGroup.systemMetricTagTimewindow === getTimeWindowFromTags(sm.systemMetricTags)))
                .filter(sm => ('payload' in sm && sm.payload !== null && Object.keys(sm.payload).length > 0));

        const series = TimeSeries.timeSeriesListMerge({
            name: "data",
            seriesList: filteredData.map(sm => getDataFromSystemMetric(sm, historyTimeRange, forecastTimeRange))
        });
        const styler = getColorStyler(series.columns(), configuredGroup.color);
        styler.columnNames.forEach(name => {
            styler.columnStyles[name]["fontSize"] = "small";
            styler.columnStyles[name]["width"] = 1;
            styler.columnStyles[name]["dashed"] = configuredGroup.dashed === true;
        });
        const legendStyler = getColorStyler(series.columns(), configuredGroup.color);
        legendStyler.columnNames.forEach(name => {
            legendStyler.columnStyles[name]["width"] = 3;
            legendStyler.columnStyles[name]["dashed"] = configuredGroup.dashed === true;
        });
        const legend_categories = configuredGroup.addToLegend
        ? filteredData
                .filter(systemMetric => series.columns().indexOf(systemMetric.id)>-1)
                .map(systemMetric => ({
                    key: systemMetric.id,
                    label: (systemMetric.system
                        ? systemMetric.system.displayName
                        : systemMetric.space
                        ? systemMetric.space.displayName
                        : systemMetric.waterCircuit
                        ? systemMetric.waterCircuit.displayName
                        : systemMetric.systemMetricTemplate.displayName),
                }))
        : [];
        return ({
            unique_id: (filteredData.length > 0 ? filteredData[0].id : Math.random()),
            displayName: (filteredData.length > 0 ? filteredData[0].systemMetricTemplate.displayName : "") + (configuredGroup.systemMetricTagFunction ? " [" + configuredGroup.systemMetricTagFunction + "]" : ""),
            name: configuredGroup.systemMetricTemplateName,
            description: filteredData.length > 0 ? filteredData[0].systemMetricTemplate.description : "",
            displayUnitString: filteredData.length > 0 ? filteredData[0].systemMetricTemplate.metric.defaultDisplayUnitString : null,
            columns: series.columns(),
            legend_categories: legend_categories,
            series: series,
            style: styler,
            legendStyle: legendStyler,
            axis: configuredGroup.axis,
            interactive: true,
        });
    })
)

const getLegendFromData = (data, grouping) => {
    if (grouping === "system") {
        let flattened_array = [];
        data.filter(ts => ts.legend_categories.length > 0).forEach(ts => {
            ts.legend_categories.forEach(lc => {
                flattened_array.push({
                    unique_id: lc.unique_id,
                    system_metric_id: lc.key,
                    system_displayName: lc.label,
                    metric_name: ts.name,
                    metric_displayName: ts.displayName,
                    metric_unit: ts.displayUnitString,
                    legendStyle: Object.assign(ts.legendStyle.columnStyles[lc.key], {key: lc.key}),
                })
            })
        })
        const group_names = Array.from(new Set(flattened_array.map(l => l.system_displayName)))
        return group_names.map(gn => ({
            unique_id: gn,
            name: gn,
            displayName: gn,
            legendStyle: styler(flattened_array.filter(l => l.system_displayName===gn).map(gi => gi.legendStyle)),
            legend_categories: flattened_array.filter(l => l.system_displayName===gn).map(gi => ({
                key: gi.system_metric_id,
                label: gi.metric_displayName,
                displayUnitString: gi.metric_unit,
            }))
        }))
    };

    return data.filter(ts => ts.legend_categories.length > 0).map(ts => ({
        unique_id: ts.unique_id,
        name: ts.name,
        displayName: ts.displayName,
        description: ts.description,
        legendStyle: ts.legendStyle,
        legend_categories: ts.legend_categories.map(lc => ({
            key: lc.key,
            label: lc.label,
            displayUnitString: ts.displayUnitString,
        }))
    }));
}

const getTimeRange = dateTime => (
    new TimeRange([moment(dateTime).subtract(14, 'days').toDate(), moment(dateTime).toDate()])
)
const getVisibleTimeRange = dateTime => (
    new TimeRange([moment(dateTime).subtract(7, 'days').toDate(), moment(dateTime).toDate()])
)

class CrossHairs extends React.Component {
    render() {
        const { x, y } = this.props;
        const style = { pointerEvents: "none", stroke: "#ccc" };
        if (!x || !y) {
            return <g />;
        } else {
            return (
                <g>
                    <line style={style} x1={0} y1={y} x2={this.props.width} y2={y} />
                    <line style={style} x1={x} y1={0} x2={x} y2={this.props.height} />
                </g>
            );
        }
    }
}

class ChartHistoryId extends React.Component {
    constructor(props) {
        super(props);
    
        this.state = {
            tracker: null,
            trackerValues: null,
            x: null,
            y: null,
            timeMarker: null,
            dashboardDateTime: moment().toDate(),
            chartTimeRange: getTimeRange(moment().toDate()),
            visibleChartTimeRange: getVisibleTimeRange(moment().toDate()),
            data: null,
            legend: null,
        };
    }

    componentDidMount() { this.handleProps(this.props); }
    UNSAFE_componentWillReceiveProps(nextProps) { this.handleProps(nextProps); }
    handleProps = (props) => {
        if(!props.data.error && !props.data.loading && props.data.me) {
            if (props.data.me.selectedBuilding) {
                const dashboardDateTime = props.data.me.selectedBuilding.lastDataPushDateTime;
                const chartTimeRange = getTimeRange(dashboardDateTime);
                const visibleChartTimeRange = getVisibleTimeRange(dashboardDateTime);

                this.setState({dashboardDateTime, chartTimeRange, visibleChartTimeRange});

                const data = getData(props.data.systemMetrics, props.systemMetricGroups, chartTimeRange, null);
                const legend = getLegendFromData(data, props.legendGrouping);
                this.setState({data, legend});
            }
        }
    }

    handleTrackerChanged = tracker => {
        if (tracker == null) {
            this.setState({ tracker, trackerValues: null, timeMarker: null, x: null, y: null });
        } else {
            this.setState({ tracker });
            const trackerValueObject = this.state.data
                .filter(smg => smg.columns.length > 0)
                .map(smg => smg.series.atTime(tracker).toJSON()['data'])
                .reduce((obj, item) => Object.assign(obj, item), {})
            this.setState({ trackerValues: trackerValueObject });
            this.setState({ timeMarker: {
                event: new TimeEvent(tracker, { y: 0 }),
                datetime: moment(tracker).format("YYYY-MM-DD HH:mm"),
            }});
        }
    }

    handleMouseMove = (x, y) => {
        this.setState({ x, y });
    }

    setVisibleChartTimeRange = (chartTimeRange) => {
        this.setState({visibleChartTimeRange: new TimeRange([
            moment.max(moment(this.state.chartTimeRange.begin()), moment(chartTimeRange.begin())).toDate(),
            moment.min(moment(this.state.chartTimeRange.end()), moment(chartTimeRange.end())).toDate()
        ])})
    }

    render() {
        if (this.props.data.error) {
            return (<Grid container><Grid item style={{height:"200px"}}><Typography gutterBottom>Error fetching chart data!</Typography></Grid></Grid>)
        };
        if (!this.state.data || this.props.data.loading) {
            return (<Grid container><Grid item style={{height:"200px"}}><CircularProgress size={50} color="secondary" /></Grid></Grid>)
        };
        if (!this.props.data.systemMetrics || this.props.data.systemMetrics.length===0) {
            return (<Grid container><Grid item style={{height:"200px"}}><Typography gutterBottom>No data available</Typography></Grid></Grid>)
        } else {
            return (
                <Grid container spacing={4}>
                    <Grid item xs={9} sm={9} md={9} lg={9} xl={9}>
                    <Resizable>
                        <ChartContainer timeRange={this.state.visibleChartTimeRange} width={800} TimeAxisStyle={axisStyle}
                                format={date => (d3.timeHour(date) < date ? moment(date).format("H[h]mm") : d3.timeDay(date) < date ? moment(date).format("H[h]") : moment(date).format("ddd DD"))}
                                onMouseMove={(x, y) => this.handleMouseMove(x, y)}
                                onTrackerChanged={tracker => this.handleTrackerChanged(tracker)}
                                enablePanZoom={true}
                                onTimeRangeChanged={this.setVisibleChartTimeRange}
                            >
                            <ChartRow height="200">
                                {this.props.systemMetricAxes && this.props.systemMetricAxes.map(a => (
                                    <YAxis key={a.name} id={a.name} label={a.label} width="60" style={axisStyle}
                                        min={a.min ? a.min : getMinValueFromData(this.state.data, a.name) }
                                        max={a.max ? a.max : getMaxValueFromData(this.state.data, a.name) }
                                        format={a.name === "status" ? ".0f" : ".2s"}
                                        tickCount={a.name === "status" ? 2 : null}
                                    />
                                ))}
                                <Charts>
                                    {this.state.data && this.state.data.map(ts => (
                                        <LineChart
                                            key={ts.name}
                                            axis={ts.axis}
                                            breakLine={false}
                                            series={ts.series}
                                            columns={ts.columns}
                                            style={ts.style}
                                            highlight={ts.interactive ? this.state.highlight : null}
                                            onHighlightChange={ts.interactive ? highlight => this.setState({highlight}) : null}
                                            selection={ts.interactive ? this.state.selection : null}
                                            onSelectionChange={ts.interactive ? selection => this.setState({selection}) : null}
                                        />
                                    ))}
                                    {this.state.timeMarker
                                    ? ([
                                        <CrossHairs key="crossHairs" x={this.state.x} y={this.state.y} />
                                    ,
                                        <EventMarker
                                            key="timeMarker"
                                            type="flag"
                                            column="y"
                                            axis={this.props.systemMetricAxes[0].name}
                                            event={this.state.timeMarker.event}
                                            info={[{ label: "Time", value: this.state.timeMarker.datetime }]}
                                            infoStyle = {{ fill: "white", opacity: 0.7, stroke: "#999", pointerEvents: "none" }}
                                            infoWidth={140}
                                            markerRadius={0}
                                            infoTimeFormat=""
                                            stemStyle={{ stroke: "#999", strokeWidth: 0, cursor: "crosshair", pointerEvents: "none" }}
                                        />
                                    ]) : (
                                        <CrossHairs x={this.state.x} y={this.state.y} />
                                    )}
                                </Charts>
                            </ChartRow>
                        </ChartContainer>
                    </Resizable>
                    </Grid>
                    <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                        {this.state.legend && this.state.legend.map(lg => (
                            <div key={lg.unique_id} style={{width:"100%"}}>
                                <Typography variant="subtitle1" title={lg.description}>{lg.displayName}</Typography>
                                <Legend className={this.props.classes.wrapped}
                                    key={lg.unique_id}
                                    type="line"
                                    align="left"
                                    stack={true}
                                    style={lg.legendStyle}
                                    categories={lg.legend_categories.map(lc => ({
                                        key: lc.key,
                                        label: lc.label + (
                                            this.state.trackerValues && !isNaN(this.state.trackerValues[lc.key])
                                            ? ' ['
                                                + parseFloat(this.state.trackerValues[lc.key]).toFixed(1)
                                                + (lc.displayUnitString && lc.displayUnitString!=="dimensionless" ? " " + lc.displayUnitString : "")
                                                + ']'
                                            : ''
                                        )
                                    }))}
                                    highlight={this.state.highlight}
                                    onHighlightChange={highlight => this.setState({highlight})}
                                    selection={this.state.selection}
                                    onSelectionChange={selection => this.setState({selection})}
                                />
                            </div>
                        ))}
                    </Grid>
                </Grid>
            )
        }
    }
}

const ChartHistoryIdQuery = gql`
query ChartHistoryIdQuery ($systemMetricIds: [ID!]!) {
    me {
        id
        selectedBuilding {
            id
            projectID
            lastDataPushDateTime
        }
    }
    systemMetrics (where:{id_in:$systemMetricIds}) {
        id
        payload
        system {
            id
            displayName
        }
        space {
            id
            displayName
        }
        waterCircuit {
            id
            displayName
        }
        systemMetricTags {
            id
            key
            value
        }
        systemMetricTemplate {
            id
            name
            displayName
            description
            metric {
                id
                name
                displayName
                description
                defaultDisplayUnitString
            }
        }
    }
}`;

export default graphql(ChartHistoryIdQuery, {
    options: (props) => ({ variables: {
        systemMetricIds: props.systemMetricIds,
        }
    })
})(withStyles(styles)(ChartHistoryId));
