import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import { Switch, Route } from 'react-router-dom';

import withStyles from '@material-ui/core/styles/withStyles';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import createPalette from '@material-ui/core/styles/createPalette';
import createTypography from '@material-ui/core/styles/createTypography';
import DefaultTheme from './DefaultTheme';

import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';

import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';

import IconButton from '@material-ui/core/IconButton';
import SvgIcon from '@material-ui/core/SvgIcon';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DomainIcon from '@material-ui/icons/Domain';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import SettingsIcon from '@material-ui/icons/Settings';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import FormBuildingSelection from './components/FormBuildingSelection';

import BuildingCardList from './pages/BuildingCardList'
import Building from './pages/Building'
import State from './pages/State'
import Operation from './pages/Operation'
import Results from './pages/Results'
import ReportList from './pages/ReportList'
import LogBook from './pages/LogBook'
import DataInput from './pages/DataInput'
import Config from './pages/Config'
import ProjectOverview from './pages/ProjectOverview'
import Settings from './pages/Settings'
import BuildingSchema from './pages/BuildingSchema'

import AppSettings from './AppSettings';
import { CurrentUserContext } from './contexts/CurrentUser';

import { WebAuth }  from 'auth0-js';

import pixel from './static/images/pixel.png';
import logo from './static/images/dnergy_white.png';

var webAuth = new WebAuth({
  domain: AppSettings.GraphQLAuthDomain,
  clientID: AppSettings.GraphQLAuthClientID,
  redirectUri: window.location.protocol + '//' + window.location.host + '/auth_callback',
  audience: AppSettings.GraphQLAuthAudience,
  responseType: 'token id_token'
});

const drawerWidth = 210;

const PresentationIcon = props => (<SvgIcon {...props}><path d="M2,3H10A2,2 0 0,1 12,1A2,2 0 0,1 14,3H22V5H21V16H15.25L17,22H15L13.25,16H10.75L9,22H7L8.75,16H3V5H2V3M5,5V14H19V5H5Z" /></SvgIcon>);
const FinanceIcon = props => (<SvgIcon {...props}><path d="M3,13H7V23H3V13M10,14H14V23H10V14M17,9H21V23H17V9M17,1H21V5H20V3.06L11.97,11.09L8,7.12L3.4,11.72L2.34,10.66L8,5L11.97,8.97L18.94,2H17V1Z" /></SvgIcon>);
const ClipboardIcon = props => (<SvgIcon {...props}><path d="M7,8V6H5V19H19V6H17V8H7M9,4A3,3 0 0,1 12,1A3,3 0 0,1 15,4H19A2,2 0 0,1 21,6V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V6A2,2 0 0,1 5,4H9M12,3A1,1 0 0,0 11,4A1,1 0 0,0 12,5A1,1 0 0,0 13,4A1,1 0 0,0 12,3Z" /></SvgIcon>);
const PressureIcon = props => (<SvgIcon {...props}><path d="M13.014,11.002c-1.107,0.008-2.008-0.884-2.016-1.987c-0.009-1.107,0.879-2.007,1.987-2.016c0.166-0.001,0.324,0.023,0.478,0.06c1.156-0.905,2.667-2.085,2.839-2.208c0.298-0.211,0.64-0.236,0.883,0.007c0.24,0.248,0.215,0.623-0.01,0.886c-0.077,0.091-1.295,1.622-2.229,2.798c0.034,0.143,0.056,0.291,0.057,0.444C15.012,10.088,14.122,10.994,13.014,11.002z M0,21c0-0.553,0.448-1,1-1h9v-2.525C6.51,16.236,4,12.91,4,9c0-4.962,4.038-9,9-9c4.963,0,9,4.038,9,9c0,3.91-2.51,7.236-6,8.475V20h9c0.553,0,1,0.447,1,1s-0.447,1-1,1H1C0.448,22,0,21.553,0,21z M13,15c3.309,0,6-2.691,6-6s-2.691-6-6-6S7,5.691,7,9S9.691,15,13,15z M25,24H1c-0.552,0-1,0.447-1,1s0.448,1,1,1h24c0.553,0,1-0.447,1-1S25.553,24,25,24z" /></SvgIcon>);
const FloorplanIcon = props => (<SvgIcon {...props}><path d="M10,5V10H9V5H5V13H9V12H10V17H9V14H5V19H12V17H13V19H19V17H21V21H3V3H21V15H19V10H13V15H12V9H19V5H10Z" /></SvgIcon>);
const CashIcon = props => (<SvgIcon {...props}><path d="M5,6H23V18H5V6M14,9A3,3 0 0,1 17,12A3,3 0 0,1 14,15A3,3 0 0,1 11,12A3,3 0 0,1 14,9M9,8A2,2 0 0,1 7,10V14A2,2 0 0,1 9,16H19A2,2 0 0,1 21,14V10A2,2 0 0,1 19,8H9M1,10H3V20H19V22H1V10Z" /></SvgIcon>);
const ReportIcon = props => (<SvgIcon {...props}><path d="M13,9H18.5L13,3.5V9M6,2H14L20,8V20A2,2 0 0,1 18,22H6C4.89,22 4,21.1 4,20V4C4,2.89 4.89,2 6,2M7,20H9V14H7V20M11,20H13V12H11V20M15,20H17V16H15V20Z" /></SvgIcon>);

const styles = theme => ({
  '@global': {
    body: {
      margin: 0,
      padding: 0,
    },
  },
  flex: {
    flex: 1,
  },
  root: {
    width: '100%',
    zIndex: 1,
    overflow: 'hidden',
  },
  appFrame: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  appBar: {
    position: 'absolute',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  hide: {
    display: 'none',
  },
  drawerPaper: {
    position: 'relative',
    height: '100%',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    width: 56,
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  drawerInner: {
    // Make the items inside not wrap when transitioning:
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  content: {
    width: '100%',
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: 24,
    height: 'calc(100% - 56px)',
    marginTop: 56,
    [theme.breakpoints.up('sm')]: {
      height: 'calc(100% - 64px)',
      marginTop: 64,
    },
  },
  iconOk: {
    fill: '#339933',
  },
  iconError: {
    fill: '#CC1111',
  },
  iconGrey: {
    fill: '#757575',
  },
  iconWhite: {
    fill: '#FFFFFF',
  },
});

const TemplateMenu = (props) => {
  const [authenticated, setAuthenticated] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [customTitle, setCustomTitle] = useState("Dnergy Building Dashboard");
  const [customTheme, setCustomTheme] = useState(DefaultTheme);
  const [clientDisplayName, setClientDisplayName] = useState("");
  const [clientLogoUrl, setClientLogoUrl] = useState("");
  const [selectedBuilding, setSelectedBuilding] = useState(null);

  const { client, classes, theme, location, history } = props;
  const { data, loading, error } = useQuery(getCurrentUser)

  const handleLogout = event => {
    client && client.resetStore();
    localStorage.removeItem('sqb_auth0_access_token');
    localStorage.removeItem('sqb_auth0_id_token');
    localStorage.removeItem('sqb_auth0_expires_at');

    webAuth.logout({
      returnTo:  window.location.protocol + '//' + window.location.host,
      clientID: AppSettings.GraphQLAuthClientID
    });
  };

  useEffect(() => {
    if (typeof localStorage !== 'undefined') {
      if (location.pathname==='/') {
        if (localStorage.getItem('sqb_auth0_access_token')==null) {
          if (authenticated) {
            setAuthenticated(false);
          }
        } else {
          history.push('/portfolio');
        }
      } else {
        if (localStorage.getItem('sqb_auth0_access_token')==null) {
          history.push('/');
          history.go();
        } else {
          if (!authenticated) {
            setAuthenticated(true);
          }
        }
      }
    }
  }, [authenticated, history, location]);

  useEffect(() => {
    const updateStateTheme = (primaryColor, secondaryColor, fontFamily) => {
      const palette = createPalette({
        type: 'light',
        primary: { main: primaryColor },
        secondary: { main: secondaryColor },
      });
      setCustomTheme(createMuiTheme({
        palette: palette,
        typography: createTypography(palette, { fontFamily: fontFamily })
      }));
    };
  
    const handleClientDashboardLayout = client => {
      if (client.logo && client.logo.file) {
        setClientLogoUrl(client.logo.file.url);
      } else {
        setClientLogoUrl("");
      };
      setClientDisplayName(client.displayName);
      updateStateTheme(
        client.themePrimaryColor ? client.themePrimaryColor : "#666666",
        client.themeSecondaryColor ? client.themeSecondaryColor : "#CCCCCC",
        client.themeFontFamily ? client.themeFontFamily : "monserrat");
    }

    if (data) {
      if (error || loading) {
        return;
      }
      if (!data.me) {
        // ignore this, SEE https://github.com/apollographql/react-apollo/issues/1314
        console.log('wait, what? no user? ***************************************')
        return;
      }
      if (!data.me.client) {
        console.log('wait, what? no client? ***************************************')
        history.push('/');
        history.go();
      } else {
        const building = data.me.selectedBuilding
          ? data.me.selectedBuilding
          : data.me.client.buildings[0];
        setSelectedBuilding(building);
        handleClientDashboardLayout(data.me.client);
        if (location.pathname==='/portfolio' && data.me.client) {
          setCustomTitle(data.me.client.displayName + " Dashboard");
        } else {
          setCustomTitle(building.displayName + " Dashboard");
        }
      }
    }
  }, [history, location, selectedBuilding, error, loading, data])

  return (
    <MuiThemeProvider theme={customTheme}>
      <CurrentUserContext.Consumer>{ me => { return (
        <div className={classes.root}>
          <div className={classes.appFrame}>
            <AppBar className={clsx(classes.appBar, drawerOpen && classes.appBarShift)}>
              <Toolbar disableGutters={!drawerOpen}>
                <img height="40" width="40"
                  alt={clientDisplayName}
                  style={{paddingLeft:"8px", paddingRight:"8px"}}
                  src={clientLogoUrl.length === 0 ? pixel : clientLogoUrl}
                  className={clsx(drawerOpen && classes.hide)}
                  />
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={() => setDrawerOpen(true)}
                  className={clsx(drawerOpen && classes.hide)}
                >
                  <MenuIcon />
                </IconButton>
                <Typography variant="h6" color="inherit" className={clsx(!drawerOpen && classes.hide)} noWrap>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
                <Typography variant="h6" color="inherit" className={classes.flex} noWrap>
                  {customTitle}
                </Typography>
                <Typography variant="h6" color="inherit" noWrap>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
                <img alt="DeltaQ Logo" height="40" src={logo} />
                <Typography variant="h6" color="inherit" noWrap>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
                <Link href="mailto:support@dnergy.io" color="inherit" target="_blank" rel="noopener" title="Contact support@dnergy.io">
                  <MailOutlineIcon />
                </Link>
                <Typography variant="h6" color="inherit" noWrap>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
                <div>
                {error ? (
                  <Grid item xs={12}>
                      <Typography gutterBottom>Error!</Typography>
                  </Grid>
                ) : loading ? (
                  <Grid item xs={12}>
                      <CircularProgress size={25} color="secondary" />
                  </Grid>
                ) : (
                  <FormBuildingSelection me={data.me} location={location} setParentBuilding={setSelectedBuilding} />
                )}
                </div>
                <Typography variant="h6" color="inherit" noWrap>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
                <Button color="inherit" onClick={handleLogout}>
                  Logout
                </Button>
                <Typography variant="h6" color="inherit" noWrap>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
              </Toolbar>
            </AppBar>
            <Drawer
              variant="permanent"
              classes={{
                paper: clsx(classes.drawerPaper, !drawerOpen && classes.drawerPaperClose),
              }}
              open={drawerOpen}
            >
              <div className={classes.drawerInner}>
                <div className={classes.drawerHeader}>
                  <img height="40" width="40" style={{marginRight:"auto", paddingLeft:"8px"}}
                    alt={clientDisplayName}
                    src={clientLogoUrl.length === 0 ? pixel : clientLogoUrl}
                    className={clsx(!drawerOpen && classes.hide)}
                    />
                  <IconButton onClick={() => setDrawerOpen(false)}>
                    {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                  </IconButton>
                </div>
                <Divider />
                <List>
                  <ListItem button onClick={ () => history.push('/portfolio')}>
                    <ListItemIcon>
                      <DomainIcon />
                    </ListItemIcon>
                    <ListItemText primary="Portfolio" />
                  </ListItem>
                </List>
                <Divider />
                { selectedBuilding &&
                <List>
                  <ListItem button onClick={ () => history.push('/buildings/'+ selectedBuilding.projectID + '/info')}>
                    <ListItemIcon>
                      <PresentationIcon />
                    </ListItemIcon>
                    <ListItemText primary="Summary" />
                  </ListItem>
                  <ListItem button onClick={ () => history.push('/buildings/'+ selectedBuilding.projectID + '/results')}>
                    <ListItemIcon>
                      <FinanceIcon />
                    </ListItemIcon>
                    <ListItemText primary="Results" />
                  </ListItem>
                  <ListItem button onClick={ () => history.push('/buildings/'+ selectedBuilding.projectID + '/operation')}>
                    <ListItemIcon>
                      <PressureIcon />
                    </ListItemIcon>
                    <ListItemText primary="Operation" />
                  </ListItem>
                </List>
                }
                { selectedBuilding &&
                <Divider />
                }
                { selectedBuilding &&
                <List>
                  <ListItem button onClick={ () => history.push('/buildings/'+ selectedBuilding.projectID + '/reports')}>
                    <ListItemIcon>
                      <ReportIcon />
                    </ListItemIcon>
                    <ListItemText primary="Reports" />
                  </ListItem>
                  <ListItem button onClick={ () => history.push('/buildings/'+ selectedBuilding.projectID + '/logbook')}>
                    <ListItemIcon>
                      <ClipboardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Logbook" />
                  </ListItem>
                  { me.role === "SUPERADMIN" &&
                    <ListItem button onClick={ () => history.push('/buildings/'+ selectedBuilding.projectID + '/state')}>
                      <ListItemIcon>
                        <ErrorOutlineIcon />
                      </ListItemIcon>
                      <ListItemText primary="State" />
                    </ListItem>
                  }
                </List>
                }
                { selectedBuilding &&
                <Divider />
                }
                { selectedBuilding &&
                <List>
                  { me.role !== "READER" &&
                    <ListItem button onClick={ () => history.push('/buildings/'+ selectedBuilding.projectID + '/datainput')}>
                      <ListItemIcon>
                        <CashIcon />
                      </ListItemIcon>
                      <ListItemText primary="Data Input" />
                    </ListItem>
                  }
                  { me.role !== "READER" &&
                    <ListItem button onClick={ () => history.push('/buildings/'+ selectedBuilding.projectID + '/config')}>
                      <ListItemIcon>
                        <FloorplanIcon />
                      </ListItemIcon>
                      <ListItemText primary="Configuration" />
                    </ListItem>
                  }
                </List>
                }
                <Divider />
                <List>
                  { me.role === "SUPERADMIN" &&
                    <ListItem button onClick={ () => history.push('/projectoverview')}>
                      <ListItemIcon>
                        <CloudDoneIcon />
                      </ListItemIcon>
                      <ListItemText primary="Projects" />
                    </ListItem>
                  }
                  { me.role !== "READER" &&
                    <ListItem button onClick={ () => history.push('/settings')}>
                      <ListItemIcon>
                        <SettingsIcon />
                      </ListItemIcon>
                      <ListItemText primary="Settings" />
                    </ListItem>
                  }
                </List>
              </div>
            </Drawer>
            <main className={classes.content}>
              <Switch>
                <Route exact path="/portfolio" component={BuildingCardList} />
                <Route exact path="/buildings/:projectID/info" component={Building} />
                <Route       path="/buildings/:projectID/operation" component={Operation}  />
                <Route       path="/buildings/:projectID/state" component={State} />
                <Route       path="/buildings/:projectID/results" component={Results} />
                <Route exact path="/buildings/:projectID/reports" component={ReportList} />
                <Route exact path="/buildings/:projectID/logbook" component={LogBook} />
                <Route       path="/buildings/:projectID/datainput" component={DataInput} />
                <Route       path="/buildings/:projectID/config" component={Config} />
                <Route exact path="/buildings/:projectID/schema" component={BuildingSchema} />
                <Route exact path="/projectoverview" component={ProjectOverview} />
                <Route exact path="/settings" component={Settings} />
              </Switch>
            </main>
          </div>
        </div>
      )}}
      </CurrentUserContext.Consumer>
    </MuiThemeProvider>
  );
}

const getCurrentUser = gql`
query getCurrentUser {
  me {
    id
    email
    name
    role
    externalBuildingRoles {
      building {
        id
        projectID
        displayName
        lastDataPushDateTime
      }
    }
    selectedBuilding {
      id
      projectID
      displayName
      lastDataPushDateTime
    }
    client {
      id
      displayName
      themeFontFamily
      themePrimaryColor
      themeSecondaryColor
      logo {
        id
        file {
          id
          url
        }
      }
      buildings {
        id
        projectID
        displayName
        lastDataPushDateTime
      }
      users {
        id
        name
        email
        role
      }
    }
  }
}`
export default withStyles(styles, { withTheme: true })(TemplateMenu);
