import React from 'react'

import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'

import ChartHistory from './ChartHistory';


const OperationMeasuredZoneTemperatures = props => {
    const {projectID} = props.match.params
    return (
        <div>
            <Typography>&nbsp;</Typography>
            <Paper style={{padding: 25}}>
                <Typography variant="subtitle1">Measured zone temperatures</Typography>
                <Typography>&nbsp;</Typography>
                <Grid container>
                    <Grid item xs={12}>
                        <ChartHistory
                            projectID={projectID}
                            systemMetricGroups={[
                                {
                                    "systemTemplateName": "systemTemplate_space",
                                    "systemMetricTemplateName": "space_temperature_air",
                                    "systemMetricTagFunction": "sensor",
                                    "axis": "temperature_air",
                                    "color": "*",
                                    "addToLegend": true,
                                },
                            ]}
                            systemMetricAxes={[
                                {
                                    "name": "temperature_air",
                                    "label": "Zone temperatures [°C]",
                                },
                            ]}
                        />
                    </Grid>
                </Grid>
            </Paper>
        </div>
    )
}

export default OperationMeasuredZoneTemperatures
