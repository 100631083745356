import React, { useState } from 'react'
import withStyles from '@material-ui/core/styles/withStyles';

import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import gql from 'graphql-tag';

import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'

import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import Switch from '@material-ui/core/Switch'

import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import * as d3 from 'd3-time'
import moment, { now } from 'moment'

import 'react-vis/dist/style.css'
import {FlexibleWidthXYPlot, XAxis, HorizontalGridLines, LineSeries, Highlight, Crosshair} from 'react-vis';

import OperationControlOld from '../components/OperationControlOld'

const COLOR_HISTORY = "#669966"
const COLOR_FORECAST = "#660099"

const styles = theme => ({
  centeredModal: {
    position: 'absolute',
    width: theme.spacing(60),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
  },
});

const getTimeRange = dateTime => (
  [moment(dateTime).subtract(2, 'days').toDate(), moment(dateTime).add(2, 'days').toDate()]
)

const OperationControl = props => {
  const { classes } = props
  const { projectID } = props.match.params

  const [dropdownStateMap, setDropdownStateMap] = useState(new Map());
  const [chartXReference, setChartXReference] = useState(moment());
  const [chartXDomain, setChartXDomain] = useState(getTimeRange(moment()));
  const [crosshairValues, setCrosshairValues] = useState([]);
  const [selectedX, setSelectedX] = useState(null);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalControlStatusSetpoint, setModalControlStatusSetpoint] = useState("Unknown");
  const [modalControlSteerMetricId, setModalControlSteerMetricId] = useState(null);
  const [modalControlSteerMetricDescription, setmodalControlSteerMetricDescription] = useState(null);
  const [modalControlContext, setModalControlContext] = useState("");
  const [lastEnableContext, setLastEnableContext] = useState("");
  const [lastDisableContext, setLastDisableContext] = useState("");
  const [modalLastEnableTime, setModalLastEnableTime] = useState(moment(now()).subtract(10, 'minutes'));
  const [modalLastDisableTime, setModalLastDisableTime] = useState(moment(now()).subtract(10, 'minutes'));

  const building = props.data.me && props.data.me.client.buildings.length > 0
  ? props.data.me.client.buildings[0]
  : props.data.me && props.data.me.externalBuildingRoles.length > 0
  ? props.data.me.externalBuildingRoles[0].building
  : null

  const activeControl = building ? building.activeControl : null
  const reference_datetime = activeControl?.controlVector?.steerMetrics?.length > 0 ? Math.max(...activeControl.controlVector.steerMetrics.map(sm => sm?.payloadUi?.payloadPast?.length > 0 ? sm.payloadUi.payloadPast[sm.payloadUi.payloadPast.length-1][0] : 0)) * 1000.0 : null
  if (reference_datetime !== chartXReference) {
    setChartXReference(reference_datetime)
    setChartXDomain(getTimeRange(reference_datetime))
  }

  const is_dropdown_active = (sm_id) => {
    return dropdownStateMap.has(sm_id) ? dropdownStateMap.get(sm_id) : false
  }
  const dropdownSteerMetric = (sm_id, sm_state) => {
    setDropdownStateMap(new Map(dropdownStateMap.set(sm_id, sm_state)))
  }

  const handleSteerModeChange = (sm_id, sm_description,  event, checked) => {
    const statusSetpoint = checked ? "Active" : "Inactive";
    const steerMetricId = sm_id;
    const steerMetricDescription= sm_description;
    setModalControlStatusSetpoint(statusSetpoint);
    setModalControlSteerMetricId(steerMetricId);
    setmodalControlSteerMetricDescription(steerMetricDescription);
    if (statusSetpoint==="Active" && (modalLastEnableTime > moment(now()).subtract(5, 'minutes'))) {
      setModalLastEnableTime(moment(now()));
      updateSetpoint(steerMetricId, sm_description, statusSetpoint, lastEnableContext);
    } else if (statusSetpoint==="Inactive" && (modalLastDisableTime > moment(now()).subtract(5, 'minutes'))) {
      setModalLastDisableTime(moment(now()));
      updateSetpoint(steerMetricId,sm_description, statusSetpoint, lastDisableContext);
    } else {
      setModalOpen(true);
    }
  };

  const handleModalControlContextChange = (event) => {
    setModalControlContext(event.target.value)
    if (modalControlStatusSetpoint==="Active") {
      setLastEnableContext(event.target.value)
    } else {
      setLastDisableContext(event.target.value)
    }
  }
  const handleModalControlCancel = (event) => {
    setModalControlStatusSetpoint("Unknown");
    setModalControlSteerMetricId(null);
    setModalOpen(false);
    setModalControlContext("");
  };
  const handleModalControlConfirm = (event) => {
    if (modalControlStatusSetpoint==="Active") {
      setModalLastEnableTime(moment(now()));
      updateSetpoint(modalControlSteerMetricId, modalControlSteerMetricDescription,  "Active", lastEnableContext);
    } else if (modalControlStatusSetpoint==="Inactive" && modalControlContext.length>=2) {
      setModalLastDisableTime(moment(now()));
      updateSetpoint(modalControlSteerMetricId,modalControlSteerMetricDescription, "Inactive", lastDisableContext);
    }
  };
  const updateSetpoint = (steerMetricId, steerMetricDescription, statusSetpoint, context) => {
    const steerMetricSteered = (statusSetpoint==="Active")
    props.createLogEntryMutation({
      variables: {
        projectID: projectID,
        name: props.data.me ? props.data.me.name + (props.data.me.client ? " (" + props.data.me.client.displayName + ")" : "") : "",
        description: "Dnergy " + (steerMetricSteered ? "Enabled" : "Disabled") + " on " + steerMetricDescription + " [" + steerMetricId + "] - " + context,
        start: moment(now()),
        end: moment(now()),
      }
    }).then(response => {
      props.updateSteerMetricSteeredMutation({
        variables: {
          id: steerMetricId,
          steered: steerMetricSteered,
        },
        refetchQueries: [
          /*{
          query: updateBuildingControlCache,
          variables: { projectID }
        }*/
      ],
      }).then(response => {
        setModalControlStatusSetpoint("Unknown")
        setModalControlSteerMetricId(null)
        setModalOpen(false)
        setModalControlContext("");
      })
    })
  }

  if (props.data.error) {
    return (<Paper style={{ padding: 25 }}><Grid container><Grid item style={{height:"200px"}}><Typography gutterBottom>Error fetching chart data!</Typography></Grid></Grid></Paper>)
  };
  if (props.data.loading) {
    return (<Paper style={{ padding: 25 }}><Grid container><Grid item style={{height:"200px"}}><CircularProgress size={50} color="secondary" /></Grid></Grid></Paper>)
  };
  if (!props.data.me || (props.data.me.client.buildings.length === 0 && props.data.me.externalBuildingRoles.length === 0) ) {
    return (<Paper style={{ padding: 25 }}><Grid container><Grid item style={{height:"200px"}}><Typography gutterBottom>No data available</Typography></Grid></Grid></Paper>)
  } else {
    if (!(building?.activeControl?.controlVector?.steerMetrics && building?.activeControl?.controlVector?.steerMetrics[0]?.displayName)) {
      return <OperationControlOld projectID={projectID} />
    }
    else {
    return (
      <div>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={modalOpen}
        >
          <div className={classes.centeredModal}>
            <Typography variant="h5" id="modal-title">
              {
                modalControlStatusSetpoint==="Active"
                ? "Start dnergy Control"
                : "Stop dnergy Control?"
              }
            </Typography>
            <br/>
            <Typography variant="body1" id="simple-modal-description">
              {
                modalControlStatusSetpoint==="Active"
                ? ""
                : "Revert to scheduled or manual control. This event is logged for quality analysis purposes, please describe the reason for shutdown."
              }
            </Typography>
            <br/>
            <TextField
              id="simple-modal-context"
              label={modalControlStatusSetpoint==="Active"
                ? "Log message [optional]"
                : "Cause for disabling DeltaQ [required]"
              }
              error={modalControlStatusSetpoint!=="Active" && modalControlContext.length<2}
              multiline
              rows="4"
              style={{minWidth:"80%"}}
              defaultValue={modalControlContext}
              variant="outlined"
              onChange={handleModalControlContextChange}
            />
            <br/>
            <br/>
            <Button variant="contained" color="primary" onClick={handleModalControlCancel}>Cancel</Button>
            &nbsp;&nbsp;&nbsp;
            <Button variant="contained" color="primary" onClick={handleModalControlConfirm}>Confirm</Button>
          </div>
        </Modal>
        <Paper style={{ padding: 25 }} key={"header"}>
          <Grid container>
            <Grid item key={"header_n"} xs={3} sm={3} md={3} lg={3} xl={3}>
              <Typography variant="subtitle1" style={{textAlign: "center"}}>BMS&nbsp;Tags</Typography>
            </Grid>
            <Grid item key={"header_l"} xs={1} sm={1} md={1} lg={1} xl={1}>
              <Box textAlign="center" m={0}>Last&nbsp;Control</Box>
              <Box textAlign="center" m={0}>
                {/* <Typography variant="caption" style={{textAlign: "center"}}>
                  {building.activeControl && building.activeControl.controlHistory ? moment(building.activeControl.controlHistory.lastControlValues.timestamp * 1000).format('YYYY-MM-DD HH:mm') : ''}
                </Typography> */}
              </Box>
            </Grid>
            <Grid item key={"header_m"} xs={1} sm={1} md={1} lg={1} xl={1}>
            <Box textAlign="center" m={0}>Selected</Box>
            <Box textAlign="center" m={0}>
                <Typography variant="caption" style={{textAlign: "center"}}>
                  {selectedX ? moment(selectedX).format('YYYY-MM-DD HH:mm') : " "}
                </Typography>
              </Box>
            </Grid>
            <Grid item key={"header_h"} xs={3} sm={3} md={3} lg={3} xl={3}>
              <Box textAlign="center" m={0}>Control&nbsp;History</Box>
              <Box textAlign="center" m={0}>
                {/* <Typography variant="caption" style={{textAlign: "center"}}>
                  Updated: {building.activeControl && building.activeControl.controlHistory ? moment(building.activeControl.controlHistory.controlValues.timestamp * 1000).format('YYYY-MM-DD HH:mm') : ''}
                </Typography> */}
              </Box>
            </Grid>
            <Grid item key={"header_v"} xs={3} sm={3} md={3} lg={3} xl={3}>
              <Box textAlign="center" m={0}>Planned&nbsp;Control</Box>
              <Box textAlign="center" m={0}>
                <Typography variant="caption" style={{textAlign: "center"}}>
                  Calculated: {building.activeControl && building.activeControl.controlVector ? moment(building.activeControl.controlVector.updatedAt).format('YYYY-MM-DD HH:mm') : ''}
                </Typography>
              </Box>
            </Grid>
            <Grid item key={"header_dd"} xs={1} sm={1} md={1} lg={1} xl={1}>
              <Box textAlign="right" m={0}></Box>
            </Grid>
            {activeControl.controlVector.steerMetrics.map(sm => ([].concat.apply([
              <Grid item key={sm.id+"_s"} xs={1} sm={1} md={1} lg={1} xl={1}>
                <Switch checked={sm.steered} onChange={(event, checked) => handleSteerModeChange(sm.id,sm.payloadUi.slug ? sm.payloadUi.slug : "", event, checked)} />
              </Grid>,
              <Grid item key={sm.id+"_n"} xs={2} sm={2} md={2} lg={2} xl={2} style={{padding:"3px"}}>
                <Tooltip id="tooltip-name" title={(sm.displayName ? sm.displayName : "") + " [" + sm.id + "]"} placement="bottom-start">
                  <Typography variant="body2">{(sm.payloadUi.slug ? sm.payloadUi.slug : "") + ((sm?.payloadUi?.unitString && sm.payloadUi.unitString !== "dimensionless" && sm.payloadUi.unitString !== "None") ? " [" + sm.payloadUi.unitString + "]" : "")}</Typography>
                </Tooltip>
              </Grid>,
              <Grid item key={sm.id+"_l"} xs={1} sm={1} md={1} lg={1} xl={1} style={{padding:"3px"}}>
                <Typography style={{textAlign: "center"}}>{
                  !sm.steered
                  ? "Auto"
                  : sm.steeringState && sm.steeringState.startsWith("clear.ok")
                  ? "Auto"
                  :!(sm.payloadUi.payloadPast && sm.payloadUi.payloadPast.length > 0)
                  ? ""
                  : sm.payloadUi.payloadPast[sm.payloadUi.payloadPast.length-1][1] === null
                  ? "Auto"
                  : sm.payloadUi.payloadPast[sm.payloadUi.payloadPast.length-1][1]
                }</Typography>
              </Grid>,
              <Grid item key={sm.id+"_m"} xs={1} sm={1} md={1} lg={1} xl={1} style={{padding:"3px"}}>
                                <Typography style={{textAlign: "center"}}>{ selectedX && sm.payloadUi.payloadPast && sm.payloadUi.payloadFuture? 
 (sm.payloadUi.payloadPast.concat(sm.payloadUi.payloadFuture)).filter(x => x[0]===(sm.payloadUi.payloadPast.map((x) => x[0]).concat(sm.payloadUi.payloadFuture.map((x) => x[0]))).sort().reduce((prev,current) => Math.abs(current - selectedX.getTime()/1000)<Math.abs(prev - selectedX.getTime()/1000) ? current : prev))[0][1]:""
                }</Typography>
              </Grid>,
              <Grid item key={sm.id+"_vv"} xs={6} sm={6} md={6} lg={6} xl={6}>{
                sm.payloadUi.payloadPast && sm.payloadUi.payloadFuture &&
                (<FlexibleWidthXYPlot
                    height={65}
                    xType="time"
                    xDomain={chartXDomain}
                    yDomain={[Math.min(0, Math.min(...sm.payloadUi.payloadPast.map(e => e[1])), Math.min(...sm.payloadUi.payloadFuture.map(e => e[1]))), Math.max(1, Math.max(...sm.payloadUi.payloadPast.map(e => e[1])), Math.max(...sm.payloadUi.payloadFuture.map(e => e[1])))]}
                  >
                    <XAxis tickSizeInner={0} tickFormat={date => (d3.timeHour(date) < date ? moment(date).format("H[h]mm") : d3.timeDay(date) < date ? moment(date).format("H[h]") : (<tspan fontWeight="bolder">{moment(date).format("ddd DD")}</tspan>))} />
                    <HorizontalGridLines />
                    <LineSeries
                      data={sm.payloadUi.payloadPast.map(e => ({ x: new Date(e[0]*1000), y: e[1] }))}
                      color={COLOR_HISTORY}
                      getNull={(d) => d.y !== null}
                      onNearestX={(datapoint, {index})=>{
                        setCrosshairValues([datapoint])
                        setSelectedX(datapoint.x)
                      }}
                      onSeriesMouseOut={()=>{setCrosshairValues([]);setSelectedX(null)}}
                    />
                    <LineSeries
                      data={sm.payloadUi.payloadFuture.map(e => ({ x: new Date(e[0]*1000), y: e[1] }))}
                      color={COLOR_FORECAST}
                      getNull={(d) => d.y !== null}
                      onNearestX={(datapoint, {index})=>{
                        if (index > 0) {
                          setCrosshairValues([datapoint])
                          setSelectedX(datapoint.x)
                        }
                      }}
                      onSeriesMouseOut={()=>{setCrosshairValues([]);setSelectedX(null)}}
                    />
                    <Crosshair values={crosshairValues} style={{line:{backgroundColor: (selectedX && (selectedX > new Date()) ? COLOR_FORECAST : COLOR_HISTORY)}}}>
                          <div width="0px"></div>
                    </Crosshair>
                    <Highlight
                      enableY={false}
                      onBrushEnd={area => setChartXDomain(area ? [area.left, area.right] : getTimeRange(reference_datetime))}
                    />
                  </FlexibleWidthXYPlot>)
              }
              </Grid>,
              <Grid item key={sm.id+"_dd"} xs={1} sm={1} md={1} lg={1} xl={1}>{
                is_dropdown_active(sm.id)
                ? (<IconButton aria-label="ExpandLess" onClick={() => dropdownSteerMetric(sm.id, false)}>
                    <ExpandLessIcon />
                  </IconButton>)
                : (<IconButton aria-label="ExpandMore" onClick={() => dropdownSteerMetric(sm.id, true)}>
                    <ExpandMoreIcon />
                  </IconButton>)}
              </Grid>],
              (is_dropdown_active(sm.id) && sm.payloadUi.groups)
              ? Object.keys(sm.payloadUi.groups).map(group_key => {
                return([
                  <Grid item key={group_key+"_s"} xs={1} sm={1} md={1} lg={1} xl={1}>
                  </Grid>,
                  <Grid item key={group_key+"_n"} xs={2} sm={2} md={2} lg={2} xl={2} style={{padding:"3px"}}>
                    <Tooltip id={group_key+"_ttn"} title={
                      (sm?.payloadUi?.groups[group_key]?.RawTrajectories && sm.payloadUi.groups[group_key].RawTrajectories.length > 0) &&
                      sm.payloadUi.groups[group_key].RawTrajectories.join("\n")
                    } placement="bottom-start">
                      <Typography variant="caption">{
                        ((sm?.payloadUi?.groups[group_key]?.displayName && sm.payloadUi.groups[group_key].displayName.length > 40)
                        ? sm.payloadUi.groups[group_key].displayName.indexOf("#") > 0
                        ? sm.payloadUi.groups[group_key].displayName.split("#").slice(-1)[0]
                        : "..." + sm.payloadUi.groups[group_key].displayName.substring(sm.payloadUi.groups[group_key].displayName.length-37, sm.payloadUi.groups[group_key].length)
                        : sm?.payloadUi?.groups[group_key]?.displayName)
                        + ((sm?.payloadUi?.groups[group_key]?.unitString && sm.payloadUi.groups[group_key].unitString !== "dimensionless" && sm.payloadUi.groups[group_key].unitString !== "None") ? " [" + sm.payloadUi.groups[group_key].unitString + "]" : "")
                      }</Typography>
                    </Tooltip>
                  </Grid>,
                  <Grid item key={group_key+"_l"} xs={1} sm={1} md={1} lg={1} xl={1} style={{padding:"3px"}}>
                    <Typography variant="caption" component="p" style={{textAlign: "center"}}>{
                      !sm.steered
                      ? "Auto"
                      : sm.steeringState && sm.steeringState.startsWith("clear.ok")
                      ? "Auto"
                      :!(sm?.payloadUi?.groups[group_key]?.payloadPast && sm.payloadUi.groups[group_key].payloadPast.length > 0)
                      ? ""
                      : sm.payloadUi.groups[group_key].payloadPast[sm.payloadUi.groups[group_key].payloadPast.length-1][1] === null
                      ? "Auto"
                      : sm.payloadUi.groups[group_key].payloadPast[sm.payloadUi.groups[group_key].payloadPast.length-1][1]
                    }</Typography>
                  </Grid>,
                //   <Grid item key={group_key+"_mf"} xs={0.5} sm={0.5} md={0.5} lg={0.5} xl={0.5} style={{padding:"3px"}}>
                //   <Typography variant="caption" style={{textAlign: "left"}}>{
                //   <Box key={3} m={0}>{2}</Box>}</Typography>
                // </Grid>,
                  <Grid item key={group_key+"_m"} xs={1} sm={1} md={1} lg={1} xl={1} style={{padding:"3px"}} >
                    <Grid container direction="row"   justify="space-between" >

                      
                      <Grid item justify="center" >
                        <Grid container direction="column" >
                        <Typography variant="caption" style={{textAlign: "center"}}>{selectedX && sm.payloadUi.groups[group_key] && sm.payloadUi.groups[group_key].payloadPast && sm.payloadUi.groups[group_key].payloadFuture ? (sm.payloadUi.groups[group_key].payloadPast.concat(sm.payloadUi.groups[group_key].payloadFuture)).filter(x => x[0]===(sm.payloadUi.groups[group_key].payloadPast.map((x) => x[0]).concat(sm.payloadUi.groups[group_key].payloadFuture.map((x) => x[0]))).sort().reduce((prev,current) => Math.abs(current - selectedX.getTime()/1000)<Math.abs(prev - selectedX.getTime()/1000) ? current : prev))[0][1]: ""}</Typography>
                        </Grid>
                      </Grid>
                      <Grid item >
                        <Grid container direction="column"  >
                            <Typography variant="caption" style={{textAlign: "right"}}>{
                          sm.payloadUi.groups[group_key] && sm.payloadUi.groups[group_key].valueMapWrite &&
                          Object.keys(sm.payloadUi.groups[group_key].valueMapWrite)
                            .sort()
                            .reverse()
                            .map(k => <Box key={k} m={0}>{`${sm.payloadUi.groups[group_key].valueMapWrite[k]}: ${k}`}</Box>)
                        }</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  

                  </Grid>,
                  <Grid item key={group_key+"_vv"} xs={6} sm={6} md={6} lg={6} xl={6}>{
                    sm.payloadUi?.groups && sm.payloadUi.groups[group_key] && sm.payloadUi.groups[group_key].payloadPast && sm.payloadUi.groups[group_key].payloadFuture &&
                    (<FlexibleWidthXYPlot
                        height={65}
                        xType="time"
                        xDomain={chartXDomain}
                        yDomain={[Math.min(0, Math.min(...sm.payloadUi.groups[group_key].payloadPast.map(e => e[1])), Math.min(...sm.payloadUi.groups[group_key].payloadFuture.map(e => e[1]))), Math.max(1, Math.max(...sm.payloadUi.groups[group_key].payloadPast.map(e => e[1])), Math.max(...sm.payloadUi.groups[group_key].payloadFuture.map(e => e[1])))]}
                    >
                        <XAxis tickSizeInner={0} tickFormat={date => (d3.timeHour(date) < date ? moment(date).format("H[h]mm") : d3.timeDay(date) < date ? moment(date).format("H[h]") : (<tspan fontWeight="bolder">{moment(date).format("ddd DD")}</tspan>))} />
                        <HorizontalGridLines />
                        <LineSeries
                          data={sm.payloadUi.groups[group_key].payloadPast.map(e => ({ x: new Date(e[0]*1000), y: e[1] }))}
                          color={COLOR_HISTORY}
                          getNull={(d) => d.y !== null}
                          onNearestX={(datapoint, {index})=>{
                            setCrosshairValues([datapoint])
                            setSelectedX(datapoint.x)
                          }}
                          onSeriesMouseOut={()=>{setCrosshairValues([]);setSelectedX(null)}}
                        />
                        <LineSeries
                          data={sm.payloadUi.groups[group_key].payloadFuture.map(e => ({ x: new Date(e[0]*1000), y: e[1] }))}
                          color={COLOR_FORECAST}
                          getNull={(d) => d.y !== null}
                          onNearestX={(datapoint, {index})=>{
                            if (index > 0) {
                              setCrosshairValues([datapoint])
                              setSelectedX(datapoint.x)
                            }
                          }}
                          onSeriesMouseOut={()=>{setCrosshairValues([]);setSelectedX(null)}}
                        />
                          <Crosshair values={crosshairValues} style={{line:{backgroundColor: (selectedX && (selectedX > new Date()) ? COLOR_FORECAST : COLOR_HISTORY)}}}>
                            <div width="0px"></div>
                          </Crosshair>
                        <Highlight
                          enableY={false}
                          onBrushEnd={area => setChartXDomain(area ? [area.left, area.right] : getTimeRange(reference_datetime))}
                        />
                      </FlexibleWidthXYPlot>
                    )}
                  </Grid>,
                  <Grid item key={group_key+"_dd"} xs={1} sm={1} md={1} lg={1} xl={1}>
                  </Grid>                  
                ])})
                : []
              )))}
          </Grid>
        </Paper>
      </div>
    )
    }
  }
}

const createLogEntry = gql`
mutation createLogEntryMutation($projectID: String!, $name: String!, $description: String!, $start: DateTime!, $end: DateTime!) {
createLogEntry(
    projectID:$projectID
    name:$name
    description:$description
    start:$start
    end:$end
) { id }}`

const updateSteerMetricSteered = gql`
mutation updateSteerMetricSteered($id: ID!, $steered: Boolean!) {
  updateSteerMetricSteered(
    id: $id
    steered: $steered
  ) { id steered }
}`;

const controlData = gql`
query controlData ( $projectID: String! ) {
    me {
        id
        name
        externalBuildingRoles ( where: { building: { projectID: $projectID } } ) {
          building {
            id
            projectID
            controlStatus
            activeControl {
              id
              controlVector {
                id
                steerMetrics {
                  id
                  slug
                  displayName
                  payloadUi
                  steered
                  steeringState
                }
                updatedAt
              }
            }
          }
        }
        client {
          id
          displayName
          buildings ( where: { projectID: $projectID } ) {
            id
            projectID
            controlStatus
            activeControl {
              id
              controlVector {
                id
                steerMetrics {
                  id
                  slug
                  displayName
                  payloadUi
                  steered
                  steeringState
                }
                updatedAt
              }
            }
          }
        }
    }
}`

export default compose(
  graphql(createLogEntry, {name : 'createLogEntryMutation'}),
  graphql(updateSteerMetricSteered, {name : 'updateSteerMetricSteeredMutation'}),
  graphql(controlData, { options: (props) => ({variables:{ projectID :props.match.params.projectID}})})
)(withStyles(styles, { withTheme: true })(OperationControl))
