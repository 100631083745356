import React from 'react'

import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import ChartHistoryWithForecast from './ChartHistoryWithForecast';


const OperationComfort = props => {
    const {projectID} = props.match.params
    return (
        <div>
            <Paper style={{padding: 25}}>
                <Typography variant="subtitle1">Outside temperature and irradiation</Typography>
                <Typography>&nbsp;</Typography>
                <Grid container>
                    <Grid item xs={12}>
                        <ChartHistoryWithForecast
                            projectID={projectID}
                            systemMetricGroups={[
                                {
                                    "systemTemplateName": "systemTemplate_facade",
                                    "systemMetricTemplateName": "facade_irradiance",
                                    "axis": "irradiance",
                                    "color": "*",
                                    "addToLegend": true,
                                },
                                {
                                    "systemTemplateName": "systemTemplate_weather",
                                    "systemMetricTemplateName": "weather_irradiance",
                                    "axis": "irradiance",
                                    "color": "#EEDC1E",
                                    "addToLegend": true,
                                },
                                {
                                    "systemTemplateName": "systemTemplate_weather",
                                    "systemMetricTemplateName": "weather_temperature_air",
                                    "axis": "temperature_air",
                                    "color": "#FF0000",
                                    "addToLegend": true,
                                },
                            ]}
                            systemMetricAxes={[
                                {
                                    "name": "temperature_air",
                                    "label": "Air temperature [°C]",
                                },
                                {
                                    "name": "irradiance",
                                    "label": "Irradiance [W/m²]",
                                },
                            ]}/>
                    </Grid>
                </Grid>
            </Paper>
            <Typography>&nbsp;</Typography>
            <Paper style={{padding: 25}}>
                <Typography variant="subtitle1">Average zone temperature and setpoints</Typography>
                <Typography>&nbsp;</Typography>
                <Grid container>
                    <Grid item xs={12}>
                        <ChartHistoryWithForecast
                            legendGrouping="system"
                            projectID={projectID}
                            systemMetricGroups={[
                                {
                                    "systemTemplateName": "systemTemplate_space",
                                    "systemMetricTemplateName": "space_temperature_air",
                                    "systemMetricTagFunction": "limit_low",
                                    "systemMetricTagCreator": "conversiontemplate",
                                    "systemMetricTagRwc": "control",
                                    "spaceDisplayname": "BuildingSpace",
                                    "axis": "temperature_air",
                                    "color": "#1a9850",
                                    "dashed": true,
                                    "addToLegend": false,
                                },

                                {
                                    "systemTemplateName": "systemTemplate_space",
                                    "systemMetricTemplateName": "space_temperature_air",
                                    "systemMetricTagFunction": "limit_high",
                                    "systemMetricTagCreator": "conversiontemplate",
                                    "systemMetricTagRwc": "control",
                                    "axis": "temperature_air",
                                    "spaceDisplayname": "BuildingSpace",
                                    "color": "#4393c3",
                                    "dashed": true,
                                    "addToLegend": false,
                                },
                                {
                                    "systemTemplateName": "systemTemplate_space",
                                    "systemMetricTemplateName": "space_temperature_air",
                                    "systemMetricTagFunction": "sensor",
                                    "modelMetricTemplateName": "TZon",
                                    "spaceDisplayname": "BuildingSpace",
                                    "axis": "temperature_air",
                                    "color": "#d6604d",
                                    "addToLegend": true,
                                },
                                {
                                    "systemTemplateName": "systemTemplate_space",
                                    "systemMetricTemplateName": "space_temperature_air",
                                    "systemMetricTagFunction": "percentile_low",
                                    "modelMetricTemplateName": "TZonPercLow",
                                    "spaceDisplayname": "BuildingSpace",
                                    "axis": "temperature_air",
                                    "color": "#d6604d",
                                    "addToLegend": true,
                                    "dashed": true,
                                },

                                {
                                    "systemTemplateName": "systemTemplate_space",
                                    "systemMetricTemplateName": "space_temperature_air",
                                    "systemMetricTagFunction": "percentile_high",
                                    "modelMetricTemplateName": "TZonPercHigh",
                                    "spaceDisplayname": "BuildingSpace",
                                    "axis": "temperature_air",
                                    "color": "#d6604d",
                                    "addToLegend": true,
                                    "dashed": true,
                                },
                                {
                                    "systemTemplateName": "systemTemplate_space",
                                    "systemMetricTemplateName": "space_temperature_air",
                                    "systemMetricTagFunction": "model",
                                    "systemMetricTagRwc": "control",
                                    "spaceDisplayname": "BuildingSpace",
                                    "axis": "temperature_air",
                                    "color": "#808080",
                                    "addToLegend": false,
                                },
                            ]}
                            systemMetricAxes={[
                                {
                                    "name": "temperature_air",
                                    "label": "Zone temperatures [°C]",
                                },
                            ]}/>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    )
}

export default OperationComfort
